import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { formatUSD, numberToFixed2 } from '@shared/lib/numbers';
import RenderAlertIcon from '@shared/ui/display/table-renderers/RenderAlertIcon';
import RenderText from '@shared/ui/display/table-renderers/RenderText';
import { RenderIncomeBadge30D, RenderIncomeBadgePlain, RenderIncomeBadgeTintedWithTooltip, } from '@shared/ui/display/table-renderers/RenderIncomeBadge';
import RenderProfileThumbnail from '@shared/ui/display/table-renderers/RenderProfileThumbnail';
import { useGetPortfolioCopiersQuery, useGetPortfolioCopiersQueueDetailsQuery, } from '@shared/api/portfolio';
import Table, { tableRendererWrapper } from '@shared/ui/display/Table';
import { IsDefined } from '@utils/js-ts';
import { SurfaceColumn } from '@src/components/styled';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
const MinHeight = 644;
const Container = styled(SurfaceColumn)(props => ({
    height: 'auto',
    padding: props.theme.spacing_sizes.l,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
    // TODO: move this outside, so that parent component can deside if the table is sticky or not
    position: 'sticky',
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
}));
// TODO: make check based on the actual user's position in the queue
const getColumnsDefinition = (t) => ([
    {
        id: 'manual_intervention',
        header: '',
        accessorKey: 'manual_intervention',
        enableSorting: false,
        meta: {
            flex: 0.02,
            align: 'center',
        },
        aggregationFn: 'count',
        // TODO: check type inference
        cell: tableRendererWrapper(RenderAlertIcon),
    },
    {
        header: t('table.copiers.delay'),
        accessorFn: model => `${numberToFixed2(model.delay)} s`,
        enableSorting: true,
        meta: {
            flex: 0.10,
        },
        cell: tableRendererWrapper(RenderText),
    },
    {
        header: t('table.copiers.copier'),
        enableSorting: false,
        meta: {
            flex: 0.18,
        },
        accessorFn: model => model.profile,
        cell: tableRendererWrapper(RenderProfileThumbnail),
    },
    {
        header: t('table.common.days_range.thirty_days'),
        accessorKey: 'past_month_difference',
        enableSorting: false,
        meta: {
            flex: 0.16,
        },
        cell: tableRendererWrapper(RenderIncomeBadge30D),
    },
    {
        header: t('table.common.days_range.one_week'),
        accessorFn: model => model.past_month_difference,
        enableSorting: false,
        meta: {
            flex: 0.12,
        },
        cell: tableRendererWrapper(RenderIncomeBadgePlain),
    },
    {
        header: t('table.common.days_range.one_day'),
        accessorFn: model => model.past_month_difference,
        enableSorting: false,
        meta: {
            flex: 0.12,
        },
        cell: tableRendererWrapper(RenderIncomeBadgePlain),
    },
    {
        header: t('table.common.days_range.all_time'),
        accessorFn: model => model.past_month_difference,
        enableSorting: false,
        meta: {
            flex: 0.18,
        },
        cell: tableRendererWrapper(RenderIncomeBadgeTintedWithTooltip(t('table.copiers.all_time_tooltip'))),
    },
    {
        header: t('table.copiers.subscription_fee'),
        accessorFn: model => formatUSD(model.bid, { compactNotation: true }),
        enableSorting: false,
        meta: {
            flex: 0.14,
        },
        cell: tableRendererWrapper(RenderText),
    },
]);
const GetCopiersQueryTake = 10;
const PortfolioCopiersTable = ({ portfolioId, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const columnsDefinition = useMemo(() => getColumnsDefinition(t), [t]);
    const [paginationState, setPaginationState] = useState({
        pageIndex: 0,
        pageSize: GetCopiersQueryTake,
    });
    const [sortingState, setSortingState] = useState([{
            id: 'delay',
            desc: false,
        }]);
    const skip = useMemo(() => paginationState.pageIndex * paginationState.pageSize, [paginationState]);
    const { data: copiersChunk, isLoading: copiersChunkIsLoading, isFetching: copiersChunkIsFetching, isError: copiersChunkIsError, } = useGetPortfolioCopiersQuery({
        portfolioId,
        skip,
        take: GetCopiersQueryTake,
        sortByDelay: sortingState.length && sortingState[0].desc ? 'desc' : 'asc',
    });
    const { data: copiersDetails, isLoading: copiersDetailsIsLoading, isFetching: copiersDetailsIsFetching, isError: copiersDetailsIsError, } = useGetPortfolioCopiersQueueDetailsQuery({ portfolioId });
    const isError = useMemo(() => copiersChunkIsError || copiersDetailsIsError, [copiersChunkIsError, copiersDetailsIsError]);
    const isChunkEmpty = useMemo(() => !IsDefined(copiersChunk) || !copiersChunk.length, [copiersChunk]);
    const hideHeader = copiersDetailsIsLoading || copiersChunkIsLoading ||
        (IsDefined(copiersChunk) && copiersChunk.length === 0);
    const showSkeleton = useMemo(() => copiersChunkIsLoading || copiersDetailsIsLoading, [copiersChunkIsLoading, copiersDetailsIsLoading]);
    const showTable = useMemo(() => (!isError && IsDefined(copiersDetails)) || copiersDetailsIsFetching || copiersChunkIsFetching, [isError, copiersDetails, copiersDetailsIsFetching, copiersChunkIsFetching]);
    return (_jsxs(Container, { style: Object.assign({}, (isChunkEmpty && ({
            minHeight: MinHeight,
        }))), children: [!copiersChunkIsFetching &&
                isError &&
                _jsx("p", { children: "error" }), showTable && (_jsx(SkeletonGroup, { isLoading: showSkeleton, children: _jsx(Table, { hideHeader: hideHeader, columnDef: columnsDefinition, data: copiersChunk || [], rowsCount: (copiersDetails === null || copiersDetails === void 0 ? void 0 : copiersDetails.copiers_count) || 0, paginationState: paginationState, onPaginationChange: setPaginationState, getRowId: (row, index) => row.id ? String(row.id) : `row-${index}`, selectionState: { 6: true }, sortingState: sortingState, onSortingChange: setSortingState, isLoading: copiersChunkIsFetching || copiersDetailsIsFetching, loadingVariant: showSkeleton ? 'skeleton' : 'loading-indicator', defaultSkeletonRows: 10, skeletonStyles: {
                        margin: `${theme.spacing_sizes.xs}px 0px`,
                    } }) }))] }));
};
export default PortfolioCopiersTable;
