import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Body, BodySemiBold, TitleSecondary } from '@src/components/styled/Typography';
import { exitSubscriptionProcess, setPortfolioForSubscription } from '@entities/portfolio';
import { ReactComponent as ArrowRightIcon } from '@icons/wolfkit-light/arrow-small-right-light.svg';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import ActiveTextButton from '@shared/ui/buttons/ActiveTextButton';
import { IsDefined } from '@utils/js-ts';
import { useGetPortfolioQuery } from '@shared/api/portfolio';
import { CenterSpacedRow, ContainerColumn, HighlightedContainer, SurfaceColumn, } from '@src/components/styled';
import Icon from '@shared/ui/icons/Icon';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import BidToQueueGeneralInfo from './BidToQueueGeneralInfo';
import BidToQueueStepper from './bid-to-queue-stepper/BidToQueueStepper';
const BidToQueueContainer = styled(SurfaceColumn)(props => ({
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: props.theme.spacing_sizes.l,
    width: '100%',
    height: 'auto',
    maxWidth: 423,
    boxShadow: '0px 1px 3px 0px #E6E8EB',
}));
const BidToQueueInner = styled(ContainerColumn)(props => ({
    alignItems: 'center',
    gap: props.theme.spacing_sizes.l,
}));
const TitleContainer = styled.div(() => ({
    display: 'inline-flex',
    alignItems: 'center',
}));
const Title = styled(TitleSecondary)(() => ({
    lineHeight: '22px',
}));
const Column = styled(ContainerColumn)(() => ({
    height: 'auto',
    alignItems: 'start',
}));
const TextLink = styled(Body)(() => ({
    textDecorationLine: 'underline',
    userSelect: 'none',
}));
const BidToQueue = ({ portfolioId, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isUserSubscribed } = useAppSelector(state => state.portfolioSubscription);
    const { data: portfolio, isLoading, isFetching, isError } = useGetPortfolioQuery({ portfolioId }, { refetchOnMountOrArgChange: true });
    useEffect(() => {
        if (!isFetching && !isError && IsDefined(portfolio)) {
            // TODO-FIX: update types & interactions with subscription
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(setPortfolioForSubscription(portfolio));
        }
    }, [portfolio, isFetching, isError, dispatch]);
    useEffect(() => () => {
        dispatch(exitSubscriptionProcess());
    }, [dispatch]);
    return ((isLoading || !isError) && (_jsx(BidToQueueContainer, { children: _jsx(SkeletonGroup, { isLoading: isLoading, children: _jsxs(BidToQueueInner, { children: [_jsx(TitleContainer, { children: _jsx(WithSkeleton, { isLoading: isLoading, width: 300, height: 22, children: _jsx(Title, { children: t('portfolio.subscription.title') }) }) }), isUserSubscribed && (_jsx(HighlightedContainer, { children: _jsxs(Column, { children: [_jsx(BodySemiBold, { children: t('portfolio.subscription.subscription_success_message') }), _jsx(ActiveTextButton, { children: _jsxs(CenterSpacedRow, { children: [_jsx(TextLink, { children: t('portfolio.subscription.check_full_statistics') }), _jsx(Icon, { size: 16, IconComponent: ArrowRightIcon })] }) })] }) })), _jsx(BidToQueueGeneralInfo, { isLoading: isLoading }), _jsx(BidToQueueStepper, { isLoading: isLoading })] }) }) })));
};
export default BidToQueue;
